import { handleActions } from 'redux-actions';
import {
  setSelectedFolderId,
  setCurrentlyViewedFolderId,
  setSearchInputValue,
  setSearchQuery,
  setSortOrder,
  setGallectionSectionType,
  unsetSelectedFolderId,
  unsetCurrentlyViewedFolderId,
  addFolderToFullViewList,
  setFullViewFolderIds,
  setSelectedSubfolderId,
  unsetSelectedSubfolderId,
} from '../actions/gallectionSection';
import GallectionSection from '../../types/gallectionSection';
import { GallectionSortOrder } from '../../types/gallection';
import { GallectionType } from '@wix/da-shared-react/pkg/types/gallection';

const defaultState: GallectionSection = {
  type: GallectionType.GALLERY,
  sortOrder: GallectionSortOrder.NEWEST,
  searchInputValue: '',
  searchQuery: '',
  fullViewFolderIds: [],
  currentPage: 1,
  totalPages: 1,
};

export default handleActions<GallectionSection, any>(
  {
    [setGallectionSectionType.toString()]: (
      state: GallectionSection,
      action: ReturnType<typeof setGallectionSectionType>
    ) => ({
      ...state,
      type: action.payload,
    }),
    [setSelectedFolderId.toString()]: (
      state: GallectionSection,
      action: ReturnType<typeof setSelectedFolderId>
    ) => ({
      ...state,
      selectedFolderId: action.payload,
      currentPage: 1,
    }),
    [unsetSelectedFolderId.toString()]: (state: GallectionSection) => ({
      ...state,
      selectedFolderId: null,
    }),
    [setSelectedSubfolderId.toString()]: (
      state: GallectionSection,
      action: ReturnType<typeof setSelectedSubfolderId>
    ) => ({
      ...state,
      selectedSubfolderId: action.payload,
      currentPage: 1,
    }),
    [unsetSelectedSubfolderId.toString()]: (state: GallectionSection) => ({
      ...state,
      selectedSubfolderId: null,
    }),
    [setCurrentlyViewedFolderId.toString()]: (
      state: GallectionSection,
      action: ReturnType<typeof setCurrentlyViewedFolderId>
    ) => ({
      ...state,
      currentlyViewedFolderId: action.payload,
    }),
    [unsetCurrentlyViewedFolderId.toString()]: (state: GallectionSection) => ({
      ...state,
      currentlyViewedFolderId: null,
    }),
    [setSortOrder.toString()]: (
      state: GallectionSection,
      action: ReturnType<typeof setSortOrder>
    ) => ({
      ...state,
      sortOrder: action.payload || state.sortOrder,
    }),
    [setSearchInputValue.toString()]: (
      state: GallectionSection,
      action: ReturnType<typeof setSearchInputValue>
    ) => ({
      ...state,
      searchInputValue: action.payload,
    }),
    [setSearchQuery.toString()]: (
      state: GallectionSection,
      action: ReturnType<typeof setSearchQuery>
    ) => ({
      ...state,
      searchQuery: action.payload || '',
    }),
    [setFullViewFolderIds.toString()]: (
      state: GallectionSection,
      action: ReturnType<typeof setFullViewFolderIds>
    ) => ({
      ...state,
      fullViewFolderIds: action.payload,
    }),
    [addFolderToFullViewList.toString()]: (
      state: GallectionSection,
      action: ReturnType<typeof addFolderToFullViewList>
    ) => ({
      ...state,
      fullViewFolderIds: [...state.fullViewFolderIds, action.payload],
    }),
  },
  defaultState
);
