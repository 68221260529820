import { PapiDaUserProfileInitStats, PapiUser } from '@wix/da-papi-types';

export enum StatsTimePeriod {
  Week = 'P7D',
  Month = 'P28D',
  Year = 'P365D',
}

export enum MySubmissionsSortBy {
  PublishDate = 'publishDate',
  PageViews = 'pageViews',
  Faves = 'faves',
  Comments = 'comments',
}

export type DetailedStats = NonNullable<
  NonNullable<PapiDaUserProfileInitStats['pageExtraData']>['detailedStats']
>;

export type MySubmissionItem = NonNullable<
  NonNullable<DetailedStats['myContent']>['timePeriods']['P7D']
>[0];

export type BenchmarkItem = NonNullable<
  NonNullable<DetailedStats['compareYourself']>['timePeriods']['P7D']
>[0];

export type GeneralStats = NonNullable<
  NonNullable<DetailedStats['general']>['timePeriods']['P7D']
>;

export type DeviceStats = NonNullable<
  NonNullable<DetailedStats['trafficByDevice']>['timePeriods']['P7D']
>;

export type CountryStats = NonNullable<
  NonNullable<DetailedStats['trafficByCountry']>['timePeriods']['P7D']
>;

export enum BenchmarkSortBy {
  ProfileVisitors = 'profileVisitors',
  DeviationViews = 'deviationsViews',
  Faves = 'faved',
  Watchers = 'watchers',
}

type StatsSection = {
  timePeriod: StatsTimePeriod;
  mySubmissionsSortBy: MySubmissionsSortBy;
  mySubmissionsIsLoading: boolean;
  benchmarkSortBy: BenchmarkSortBy;
  benchmarkIsLoading: boolean;
  visitorsIsLoading: boolean;
  matchingUsers: PapiUser[];
  stats: Omit<DetailedStats, 'traffic' | 'general'> & {
    traffic?: DetailedStats['traffic'];
    general?: DetailedStats['general'];
    trafficByDevice?: DetailedStats['trafficByDevice'];
    trafficByCountry?: DetailedStats['trafficByCountry'];
    compareYourself?: DetailedStats['compareYourself'];
    recentVisitors?: DetailedStats['recentVisitors'];
    hasCalculatedStats?: boolean;
  };
};

export default StatsSection;
