import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ModalFormDialog from '@wix/da-shared-react/pkg/Popup/ModalFormDialog';
import { EditorFlavourConfigs } from '@wix/da-shared-react/pkg/DaEditorWrapper/config';
import { useGroupAboutForm } from './hooks/useGroupAboutForm';

import TextInput from '../../../_partials/Form/TextInput';
import EditorField from '../../../_partials/Form/EditorField';

// Merge the text theme with the editor theme (content vs editor toolbars etc)
import mergeTheme from '@wix/da-shared-react/pkg/DaEditorWrapper/Themes/mergeTheme';
import textTheme from '@wix/da-shared-react/src/DaEditorWrapper/Themes/Viewer/groupabout.scss';
import editorTheme from '@wix/da-shared-react/src/DaEditorWrapper/Themes/Editor/groupabout.scss';
const descriptionTheme = mergeTheme(textTheme, editorTheme);

import s from './EditPopup.scss';

export interface Props {
  moduleId: number;
  onClose: () => void;
}

const EditPopup: React.FC<Props> = ({ moduleId, onClose }) => {
  const { t } = useTranslation();
  const { isInitialized, requestSubmit, formContext } = useGroupAboutForm(
    moduleId,
    {
      onSubmitSuccess: onClose,
    }
  );
  const {
    formState: { isDirty, isValid },
  } = formContext;

  if (!isInitialized) {
    return null;
  }

  return (
    <FormProvider {...formContext}>
      <ModalFormDialog
        isOpen
        showHeaderBorder
        showFooterBorder
        title={t('widgets.group_about.edit.header')}
        submitBtnLabel={t('common.save')}
        onSubmit={requestSubmit}
        onCancel={onClose}
        onClose={onClose}
        disabledSubmit={!isValid || !isDirty}
      >
        <div className={s['content-wrapper']}>
          <div className={s['content']}>
            <EditorField
              name="description"
              label={t('widgets.group_about.edit.description.label')}
              subLabel={t('widgets.group_about.edit.description.subLabel')}
              placeholder={t(
                'widgets.group_about.edit.description.placeholder'
              )}
              flavour={EditorFlavourConfigs.GROUP_ABOUT}
              theme={descriptionTheme}
            />
            <TextInput
              name="tagline"
              label={t('widgets.group_about.edit.tagline.label')}
              placeholder={t('widgets.group_about.edit.tagline.placeholder')}
            />
          </div>
        </div>
      </ModalFormDialog>
    </FormProvider>
  );
};
EditPopup.displayName = 'GroupAboutEditPopup';

export default EditPopup;
