import { useCallback, useEffect, useState } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import { useEffectOnceOnMount } from '@wix/da-hooks/pkg/useEffectOnceOnMount';
import yupResolver from '@wix/da-shared-react/pkg/utils/yupResolver';
import useModuleConfig from '@wix/da-gruser-shared/pkg/hooks/useModuleConfig';
import { fetchProfileOwnerTaglineSuccess } from '../../../../../actions/profileOwner';
import { FormValues } from '../../types';
import { configAdapter, submitAdapter } from '../adapters';
import { validationSchema } from '../validationSchema';

interface ServerField extends FieldValues {
  server?: { type: String; message: String };
}
export interface UseModuleConfigFormProps {
  onConfigFetchSuccess?: () => void;
  onSubmitSuccess?: () => void;
  onSubmitError?: (errors: any) => void;
  moduleName?: string;
}

// Get module config from the module endpoint
// configAdapter: Transforms the config data into form data
// submitAdapter: Transforms the form data into request data
// validate: Validates form values, returns errors on failure
export function useGroupAboutForm(
  moduleId: number,
  {
    onConfigFetchSuccess,
    onSubmitSuccess,
    onSubmitError,
  }: UseModuleConfigFormProps
) {
  const [renderVersion, setRenderVersion] = useState<number>();
  const {
    moduleConfig,
    resetConfig,
    fetchModuleConfig,
    error: configError,
    isSaving,
    hasJustFinishedFetching,
    hasJustFinishedSaving,
    saveModuleConfig,
  } = useModuleConfig(moduleId, 'group_about');

  const { config } = moduleConfig;
  const isInitialized = !!config;

  const formContext = useForm<FormValues | ServerField>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver:
      validationSchema &&
      yupResolver(validationSchema, {
        abortEarly: false,
      }),
    defaultValues: configAdapter(moduleConfig) as any,
  });
  const { reset, handleSubmit, errors, setError, getValues } = formContext;

  // Set server errors to form error
  const hasError = configError || Object.keys(errors).length > 0;
  useEffect(() => {
    if (configError) {
      setError('server', { type: 'server', message: configError });
    }
  }, [setError, configError]);

  // Update profile owner user tagline in the store
  const tagline = getValues('tagline');
  const fetchTaglineSuccess = useAction(fetchProfileOwnerTaglineSuccess);
  const updateTagline = useCallback(() => {
    fetchTaglineSuccess({ tagline });
  }, [fetchTaglineSuccess, tagline]);

  // Fetch config on initial mount
  useEffectOnceOnMount(() => {
    fetchModuleConfig();
  });

  // Trigger optional success callbacks
  useEffect(() => {
    if (hasJustFinishedFetching) {
      onConfigFetchSuccess?.();
    }
  }, [hasJustFinishedFetching, onConfigFetchSuccess]);

  // Trigger optional success callbacks
  useEffect(() => {
    if (hasJustFinishedSaving && !hasError) {
      onSubmitSuccess?.();

      // this clears the config endpoint data from the store, forcing a refetch
      // otherwise this form could only be loaded once per page load
      resetConfig();

      updateTagline();
    }
  }, [
    hasJustFinishedSaving,
    hasError,
    onSubmitSuccess,
    resetConfig,
    updateTagline,
  ]);

  // If moduleConfig changed, reset the form with the new values
  useEffect(() => {
    if (moduleConfig?.config && moduleConfig.config.version !== renderVersion) {
      // Transform the module config and options through a configAdapter into
      reset(configAdapter(moduleConfig) as any);
      // Trigger re-render if the version changed
      setRenderVersion(moduleConfig.config.version);
    }
  }, [reset, moduleConfig, renderVersion, configAdapter]);

  // Start saving config
  const requestSubmit = useCallback(() => {
    if (isSaving) return;
    handleSubmit(
      data => {
        saveModuleConfig(submitAdapter(data as any));
      },
      submitErrors => {
        onSubmitError && onSubmitError(submitErrors);
      }
    )();
  }, [isSaving, handleSubmit, saveModuleConfig, submitAdapter, onSubmitError]);

  return {
    formContext,
    requestSubmit,
    moduleConfig,
    isInitialized,
    errors,
  };
}

export default useGroupAboutForm;
