import {
  put,
  call,
  all,
  takeEvery,
  select,
  takeLatest,
} from 'redux-saga/effects';
import history from '@wix/da-shared-react/pkg/redux/routing/history';
import { Url } from '@wix/da-url';
import {
  unsetSelectedFolderId,
  unsetCurrentlyViewedFolderId,
  setSelectedFolderId,
  clearSearch,
  setFullViewFolderIds,
  setSearchQuery,
  setSearchInputValue,
  setCurrentlyViewedFolderId,
  setSortOrder,
  setSelectedSubfolderId,
  unsetSelectedSubfolderId,
} from '../actions/gallectionSection';
import {
  getSearchInputValue,
  getGallectionSectionType,
  getSortOrder,
  getSearchQuery,
  getCurrentlyViewedFolder,
  getSelectedSubfolder,
} from '../selectors/gallectionSection';
import { getDefaultFolder } from '../selectors/folders';
import { getProfileOwnerUser, getIsPagedMode } from '../selectors/users';
import { isSectionLoading } from '../selectors/sections';
import { DEFAULT_GALLECTION_SORT_ORDER } from '../components/Sections/GallectionsSection/consts';
import { pageViewHandler, logSearchSubmit } from './biLogger';

export function* updateURLSaga() {
  if (typeof window === 'undefined') {
    return;
  }
  const isLoading: ReturnType<typeof isSectionLoading> = yield select(
    isSectionLoading
  );
  if (isLoading) {
    return;
  }
  const user: ReturnType<typeof getProfileOwnerUser> = yield select(
    getProfileOwnerUser
  );
  const defaultFolder: ReturnType<typeof getDefaultFolder> = yield select(
    getDefaultFolder
  );
  const gallectionType: ReturnType<typeof getGallectionSectionType> =
    yield select(getGallectionSectionType);
  const currentlyViewedFolder: ReturnType<typeof getCurrentlyViewedFolder> =
    yield select(getCurrentlyViewedFolder);
  const selectedSubfolder: ReturnType<typeof getSelectedSubfolder> =
    yield select(getSelectedSubfolder);
  const searchQuery: ReturnType<typeof getSearchQuery> = yield select(
    getSearchQuery
  );
  const sortOrder: ReturnType<typeof getSortOrder> = yield select(getSortOrder);

  const currentFolderOrSubfolder = selectedSubfolder || currentlyViewedFolder;

  const includeFolderInURL =
    currentlyViewedFolder &&
    currentlyViewedFolder.folderId !== defaultFolder?.folderId;

  const includeSortOrder = sortOrder !== DEFAULT_GALLECTION_SORT_ORDER;

  const newUrl = Url.userGalleryPath({
    username: user.username,
    gallectionType,
    folderId: includeFolderInURL
      ? currentFolderOrSubfolder?.folderId
      : undefined,
    folderName: includeFolderInURL ? currentFolderOrSubfolder?.name : undefined,
    searchQuery,
    sortOrder: includeSortOrder ? sortOrder : undefined,
  });

  const isPaged = yield select(getIsPagedMode);
  if (isPaged) {
    // FOLDER_DEVIATIONS module is only loaded on init/gallection
    // And we need its totalPageCount prop in order to show the pagination correctly
    if (typeof window !== 'undefined') {
      window.location.href = newUrl;
    }
  } else {
    history.push(newUrl);
  }
}

export function* setSelectedFolderIdSaga(
  action: ReturnType<typeof setSelectedFolderId>
) {
  const searchInputValue = yield select(getSearchInputValue);
  if (searchInputValue) {
    yield all([put(setSearchQuery('')), put(setSearchInputValue(''))]);
  }
  yield all([
    put(setFullViewFolderIds([action.payload])),
    put(setCurrentlyViewedFolderId(action.payload)),
    put(unsetSelectedSubfolderId()),
  ]);
}

export function* unsetSelectedFolderIdSaga(
  action: ReturnType<typeof unsetSelectedFolderId>
) {
  yield put(setFullViewFolderIds([]));
}

export function* setSearchQuerySaga(action: ReturnType<typeof setSearchQuery>) {
  if (action.payload) {
    // if not empty search query
    yield all([
      put(unsetSelectedFolderId()),
      put(unsetCurrentlyViewedFolderId()),
      call(logPageViewIfNotLoading),
      call(logSearchSubmit, action.payload),
    ]);
  }
}

export function* clearSearchSaga(action: ReturnType<typeof clearSearch>) {
  const defaultFolder = yield select(getDefaultFolder);
  yield all([
    put(setSearchQuery('')),
    put(setSearchInputValue('')),
    put(setSelectedFolderId(defaultFolder?.folderId)),
  ]);
}

export function* logPageViewIfNotLoading() {
  const isLoading = yield select(isSectionLoading);
  if (isLoading) {
    return;
  }
  yield call(pageViewHandler);
}

export default [
  takeEvery(setSelectedFolderId, setSelectedFolderIdSaga),
  takeEvery(unsetSelectedFolderId, unsetSelectedFolderIdSaga),
  takeEvery(setSearchQuery, setSearchQuerySaga),
  takeEvery(clearSearch, clearSearchSaga),
  takeLatest(
    [
      unsetSelectedFolderId,
      unsetSelectedSubfolderId,
      setCurrentlyViewedFolderId,
      setSelectedSubfolderId,
      setSearchQuery,
      setSortOrder,
    ],
    updateURLSaga
  ),
  takeLatest(
    [setCurrentlyViewedFolderId, setSelectedSubfolderId],
    logPageViewIfNotLoading
  ),
];
