import React, { useRef } from 'react';
import classnames from 'classnames';
import { PapiDeviation } from '@wix/da-papi-types';
import DeviationMediaService from '@wix/da-shared-react/pkg/utils/DeviationMediaService';
import { useDeviationMatureFilter } from '@wix/da-shared-react/pkg/utils/hooks/useDeviationMatureFilter';

import s from './ProfileHeroV2.scss';

export interface Props {
  coverDeviation?: PapiDeviation;
  showProfileHeroAid?: boolean;
  className?: string;
}

export const ProfileHeroV2: React.FC<Props> = ({
  coverDeviation,
  showProfileHeroAid,
  className,
}) => {
  const rootDivRef = useRef<HTMLDivElement>(null);
  const { showBlocked } = useDeviationMatureFilter(coverDeviation);

  let image;
  if (coverDeviation) {
    if (showBlocked) {
      image = DeviationMediaService.getSmallestThumb(coverDeviation);
    } else {
      image =
        DeviationMediaService.getDeviationFile(
          coverDeviation,
          'fullview-cover'
        ) || DeviationMediaService.getDeviationFile(coverDeviation, 'fullview');
    }
  }

  return (
    <div
      ref={rootDivRef}
      className={classnames(
        s['root'],
        !image && s['no-image'],
        showProfileHeroAid && s['is-ftue'],
        className,
        showBlocked && s['mature-blur']
      )}
      style={{
        backgroundImage: image ? `url(${image.src})` : undefined,
      }}
    >
      {showProfileHeroAid && <div className={s['meteors']} />}
    </div>
  );
};
ProfileHeroV2.displayName = 'ProfileHeroV2';

export default ProfileHeroV2;
