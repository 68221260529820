import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import i18next from 'i18next';
import { putErrorNotification } from '@wix/da-shared-react/pkg/utils/saga';
import { getCurrentUserHasCore } from '@wix/da-shared-react/pkg/publicSession/selectors';
import {
  PapiDaUserProfileProfileskinsList,
  PapiRequestDaUserProfileProfileskinsList,
} from '@wix/da-papi-types';
import {
  takeStreamFetch,
  fetchStreamContents,
} from '@wix/da-shared-react/pkg/Stream/helpers';
import {
  selectors as streamSelectors,
  withOffset,
} from '@wix/da-shared-react/pkg/Stream';
import { getActiveSkin } from '../selectors/profileSkins';
import {
  papiSkinToFedSkin,
  ProfileSkin,
  profileSkinsStreamId,
} from '../../types/profileSkins';
import { isApiError } from '@wix/da-http-client';
import { runModuleInitActions as runModuleInitActionsNewGrusers } from '@wix/da-gruser-shared/pkg/redux/sagas/modules';
import { ModuleType } from '../../types/modules';
import {
  closeSkinSelector,
  fetchMoreProfileSkins,
  finishSaving,
  saveActiveSkin,
  saveSkin,
} from '../actions/profileSkins';
import {
  getModuleIdByModuleName as getModuleIdByModuleNameNewGrusers,
  getModuleVersion as getModuleVersionNewGrusers,
} from '@wix/da-gruser-shared/pkg/redux/selectors/modules';
import {
  getProfileGruser,
  getProfileOwnerUser,
  getIsGroupProfile,
} from '../selectors/users';

import { requestPuppyGruser } from './gruserHelpers';

function* handleFetchProfileSkins() {
  const { offset, limit } = yield select(state => ({
    offset: withOffset.selectors.getNextOffset(state, profileSkinsStreamId),
    limit: streamSelectors.getItemsPerFetch(state, profileSkinsStreamId),
  }));
  yield fetchStreamContents<
    PapiRequestDaUserProfileProfileskinsList,
    PapiDaUserProfileProfileskinsList
  >({
    streamId: profileSkinsStreamId,
    url: '/profileskins/list',
    papiProject: 'dauserprofile',
    params: { limit, offset },
    getStreamData: result => ({
      hasMore: !!result.hasMore,
      items: result.results.map(papiSkinToFedSkin),
      nextOffset: result.nextOffset,
    }),
  });
}

function* handleSaveActiveSkin() {
  const activeSkin: ProfileSkin = yield select(getActiveSkin);
  yield put(saveSkin(activeSkin));
}

function* handleSaveSkin(action) {
  const gruser = yield select(getProfileGruser);
  const moduleId = yield select(state =>
    getModuleIdByModuleNameNewGrusers(state, gruser, ModuleType.COVER_DEVIATION)
  );
  const { skin } = action.payload;
  const moduleVersion: number = yield select(state =>
    getModuleVersionNewGrusers(state, gruser, moduleId)
  );
  const currentUserHasCore: boolean = yield select(getCurrentUserHasCore);
  const isGroupProfile: boolean = yield select(getIsGroupProfile);
  const { username: profileOwnerUsername } = yield select(getProfileOwnerUser);

  if (!currentUserHasCore && !isGroupProfile) {
    yield put(closeSkinSelector());
    return;
  }

  if (
    moduleId === undefined ||
    moduleVersion === undefined ||
    !profileOwnerUsername
  ) {
    yield putErrorNotification(
      i18next.t('skin.selector.save.error.noModuleFatal')
    );
    yield put(finishSaving());
    return;
  }

  const data = {
    gruserid: gruser.gruserId,
    gruser_typeid: gruser.gruserTypeid,
    moduleid: moduleId,
    version: moduleVersion,
    profile_skin_template_name: skin.skinId,

    image_display_mode: skin?.skin?.imageDisplayMode,
    image_deviation_id: skin?.skin?.image?.deviationId,
    theme: skin?.skin?.baseTheme,
  };

  const response = yield call(
    requestPuppyGruser,
    {
      method: 'post',
      url: '/module/cover_deviation/save_skin',
      data,
    },
    undefined,
    'gruser'
  );

  if (!response || isApiError(response) || !response.moduleData) {
    yield putErrorNotification(
      i18next.t('skin.selector.save.error.saveFailed')
    );
    yield put(finishSaving());
    return;
  }

  if (response.moduleData) {
    yield call(runModuleInitActionsNewGrusers, gruser, response);
  }

  yield put(closeSkinSelector());
}

export default function* rootProfileSkinsSaga() {
  yield all([
    takeStreamFetch(fetchMoreProfileSkins, handleFetchProfileSkins),
    takeEvery(saveActiveSkin, handleSaveActiveSkin),
    takeEvery(saveSkin, handleSaveSkin),
  ]);
}
