import React from 'react';
import { useTranslation } from 'react-i18next';
import { isGroup as getIsGroup } from '@wix/da-shared-react/pkg/User/helpers';
import { actions as ModalActions } from '@wix/da-shared-react/pkg/Modals';
import { ModalType } from '@wix/da-shared-react/pkg/Modals/redux/types';
import EmptyState from '../../../EmptyState';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileOwner } from '../../../../selectors/users';

import s from './FeedEmpty.scss';

interface Props {}

const FeedEmpty: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const profileOwner = useSelector(getProfileOwner);
  const isGroup = getIsGroup(profileOwner.user);

  let emptyStateTitle, emptyStateSubtitle, emptyStateButtonLabel;

  if (isGroup) {
    emptyStateTitle = t('widgets.feed.empty.title.owner.group');
    emptyStateSubtitle = t('widgets.feed.empty.subtitle.owner.group');
    emptyStateButtonLabel = t('widgets.feed.empty.CTALabel.owner.group');
  } else {
    emptyStateTitle = t('widgets.feed.empty.title.owner');
    emptyStateSubtitle = t('widgets.feed.empty.subtitle.owner');
    emptyStateButtonLabel = t('widgets.feed.empty.CTALabel.owner');
  }

  return (
    <EmptyState
      view="small"
      className={s['empty-state']}
      title={emptyStateTitle}
      subtitle={emptyStateSubtitle}
      buttonLabel={emptyStateButtonLabel}
      withButton
      onButtonClick={() =>
        dispatch(
          isGroup
            ? ModalActions.pushModal(ModalType.JOURNAL, {
                params: {
                  refreshAfterSubmit: true,
                  groupDestination: profileOwner,
                },
              })
            : ModalActions.pushModal(ModalType.STATUS_UPDATE, {
                wrapInModal: false,
              })
        )
      }
    />
  );
};
FeedEmpty.displayName = 'FeedEmpty';

export default FeedEmpty;
