import React from 'react';
import { compose } from 'redux';
import { Url } from '@wix/da-url';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { ShopItemType } from '../../../../../types/shop';
import withSectionFetchSpinner from '../../../../decorators/withSectionFetchSpinner';
import ShopGrid from '../ShopGrid';
import AdoptablesEmptyState from '../_partials/AdoptablesEmptyState';
import { BiData, DefaultBiEvent } from '@wix/da-bi/pkg/events';
import UploadMore from '../_partials/UploadMore';
import s from '../ShopSection.scss';

export interface Props {
  className?: string;
}

const enhance = compose(withSectionFetchSpinner);

export const ShopAdoptables: React.FC<Props> = ({ className }) => {
  return (
    <BiLoggerContextProvider value={{ sectionname: 'Adoptables' }}>
      <div className={s['section-with-no-header']}>
        <ShopGrid
          className={className}
          itemType={ShopItemType.ADOPTABLES}
          emptyState={<AdoptablesEmptyState view={'large'} />}
          uploadMore={
            <UploadMore
              itemType={ShopItemType.ADOPTABLES}
              onCreate={() => {
                if (window) {
                  window.location.replace(Url.studio({ openSubmitForm: true }));
                }
              }}
              biData={BiData<DefaultBiEvent>({
                evid: 2,
                click_info: 'create_adoptable',
                sectionname: 'adoptables',
              })}
            />
          }
        />
      </div>
    </BiLoggerContextProvider>
  );
};

ShopAdoptables.displayName = 'ShopAdoptables';

export default enhance(ShopAdoptables) as React.ComponentType<Props>;
