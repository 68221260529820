import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiData, DonatePointsClickBiEvent } from '@wix/da-bi/pkg/events';
import PointsIcon from '@wix/da-ds/pkg/Icons/Points';
import ProgressBar from '@wix/da-shared-react/pkg/ProgressBar';
import { useRestrictToLoggedIn } from '@wix/da-shared-react/pkg/utils/hooks/useRestrictToLoggedIn';
import Flex from '@wix/da-ds/pkg/Flex';
import c from 'classnames';
import TextRenderer from '@wix/da-shared-react/pkg/TextRenderer';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import DonationViewer from '@wix/da-shared-react/pkg/DaEditorWrapper/Viewer/Donation';
import DonationTipTapViewer from '@wix/da-editor-tiptap/pkg/components/Viewer/presets/UserDonations';
import { isEditorTextEmpty } from '@wix/da-shared-react/pkg/DaEditorWrapper/utils';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import { useModuleVersion } from '@wix/da-gruser-shared/pkg/hooks/useModule';
import { useGruser } from '@wix/da-gruser-shared/pkg/hooks/useGruser';
import useModulePrivileges from '@wix/da-gruser-shared/pkg/hooks/useModulePrivileges';
import BiLink from '@wix/da-bi/pkg/Components/BiLink';
import { PapiModuleDonations } from '@wix/da-papi-types';
import DonatePointsPopup from './DonatePointsPopup';

import s from './Donations.scss';

export interface Props {
  moduleId: number;
}

export const Donations: React.FC<Props> = ({ moduleId }) => {
  const { t } = useTranslation();
  const { gruserOwner } = useGruser();
  const moduleVersion = useModuleVersion(moduleId);
  const modulePrivs = useModulePrivileges(moduleId);
  const isOwner = modulePrivs?.edit;
  const { moduleData } = useModuleData<PapiModuleDonations>(moduleId);
  const { title, goal, amount, donors, textContent: description } = moduleData;

  const [showDonatePointsPopup, setShowDonatePointsPopup] = useState(false);
  const hideDonatePopup = useCallback(() => {
    setShowDonatePointsPopup(false);
  }, []);
  const openDonatePopup = useCallback(() => {
    setShowDonatePointsPopup(true);
  }, []);

  const { restrictToLoggedIn } = useRestrictToLoggedIn();
  const openDonatePopupIfLoggedIn = restrictToLoggedIn(
    ReferralType.DONATE_POINTS,
    openDonatePopup
  );

  let hasDescription = false;
  if (description) {
    hasDescription = !isEditorTextEmpty(description);
  }
  const percentage = goal ? (amount / goal) * 100 : 0;
  const hasReachedGoal = amount >= goal;
  return (
    <>
      <div className={c(s['root'], !hasDescription && s['no-description'])}>
        <div className={s['wrapper']}>
          {/* title and description */}
          {title && <div className={s['title']}>{title}</div>}
          {hasDescription && (
            <div className={s['description']}>
              <TextRenderer
                textContent={description}
                reactViewer={DonationViewer}
                tipTapViewer={DonationTipTapViewer}
                legacyTheme={'default'}
                viewerKey={`donation-${moduleId}-${moduleVersion}`}
                cookieType={MeasuredCookieType.USER_DONATIONS}
                defaultSize={{
                  desktop: { width: 534, height: 400 },
                  mobile: { width: 380, height: 400 },
                }}
              />
            </div>
          )}

          {/* goal */}
          <Flex className={s['section-title']} alignItems="center">
            <span>{goal}</span>
            <PointsIcon className={s['points-icon']} />
          </Flex>
          <div className={s['section-description']}>
            {t('widgets.donations.goal')}
            {isOwner && !goal && (
              <span className={s['empty-goal']}>
                {t('widgets.donations.emptyGoal')}
              </span>
            )}
          </div>

          {/* donors */}
          <div className={s['section-title']}>{donors}</div>
          <div className={s['section-description']}>
            {t('widgets.donations.donors')}
          </div>

          {/* status */}
          <div className={s['section-title']}>
            {t('widgets.donations.status')}
          </div>
          <div
            className={c(
              s['section-description'],
              s['section-description-status']
            )}
          >
            {t('widgets.donations.status.progress', {
              progress: `${amount}/${goal}`,
            })}
          </div>

          <ProgressBar
            percentage={percentage}
            className={s['progressbar-root']}
          />

          {hasReachedGoal && (
            <div className={s['goal-reached']}>
              {t('widgets.donations.reached.thanks')}{' '}
              <span className={s['accent']}>
                {t('widgets.donations.reached.helped')}
              </span>
            </div>
          )}
        </div>
      </div>
      {!isOwner && (
        <div className={s['footer']}>
          <BiLink
            className={c(s['donate-button'])}
            onClick={openDonatePopupIfLoggedIn}
            biData={BiData<DonatePointsClickBiEvent>({
              evid: 286,
              recipientid: gruserOwner?.useridUuid ?? '',
            })}
          >
            <span>{t('widgets.donations.button.donate')}</span>
          </BiLink>
        </div>
      )}
      {showDonatePointsPopup && gruserOwner && (
        <DonatePointsPopup
          username={gruserOwner.username}
          moduleId={moduleId}
          close={hideDonatePopup}
        />
      )}
    </>
  );
};

export default Donations;
