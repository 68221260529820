import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiDeviation } from '@wix/da-papi-types';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import FeedThumb from '../../../../UserWidgets/_partials/FeedThumb';
import { getDeviationResourceType } from '@wix/da-shared-react/pkg/DeviationViews/_helpers';
import MeasuredViewerContainerSizeContextProvider from '@wix/da-shared-react/pkg/DaEditorWrapper/ViewerContainerSizeContext/MeasuredViewerContainerSizeContextProvider';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import LoadableDuperbrowseContext from '../../../../../contexts/LoadableDuperbrowseContext';
import StreamPagination from '../../../_partials/StreamPagination';
import { BiData, WatchMenuItemSelectionBiEvent } from '@wix/da-bi/pkg/events';

import s from './PostsFeed.scss';

export interface Props {
  streamId: string;
  className?: string;
  isOwner: boolean;
  canRemovePost: boolean;
  disablePinningAndFeaturing?: boolean;
  withWatchOrSubscribeButtons?: boolean;

  items: PapiDeviation[];

  isItemFeatured: (item: PapiDeviation) => boolean;
  removeItem: (item: PapiDeviation) => void;
  editItem: (item: PapiDeviation) => void;
  featureItem: (item: PapiDeviation) => void;
  unfeatureItem: (item: PapiDeviation) => void;
  pinItem: (item: PapiDeviation) => void;
  unpinItem: () => void;
}

const PostsFeed: React.FC<Props> = ({
  isOwner,
  items,
  streamId,
  className,
  removeItem,
  featureItem,
  unfeatureItem,
  isItemFeatured,
  canRemovePost,
  disablePinningAndFeaturing,
  withWatchOrSubscribeButtons,
}) => {
  const { t } = useTranslation();

  return (
    <ErrorBoundary debugComponent="PostsFeed">
      <LoadableDuperbrowseContext
        streamId={streamId}
        parent={{ title: t('duperbrowse.backButton.profile.posts') }}
      >
        <MeasuredViewerContainerSizeContextProvider
          cookieType={MeasuredCookieType.BROWSE_POST_FEED}
          cookieDim={'width'}
          className={classnames(s['items'], className)}
          innerPadding={16}
        >
          {items.map((item, index) => {
            const isFeatured = isItemFeatured(item);
            const biData = BiData<WatchMenuItemSelectionBiEvent>({
              evid: 424,
              typeid: getDeviationResourceType(item),
              itemid: item.deviationId,
              rank: index,
            } as any);

            const extraActions = [] as {
              label: string;
              onClick: () => void;
              biData: any;
            }[];
            if (isOwner && item.isPublished && !disablePinningAndFeaturing) {
              if (isFeatured) {
                extraActions.push({
                  label: t('widgets.feed.unfeature'),
                  onClick: () => unfeatureItem(item),
                  biData: { ...biData, menu_item: 'unfeature' },
                });
              } else {
                extraActions.push({
                  label: t('widgets.feed.feature'),
                  onClick: () => featureItem(item),
                  biData: { ...biData, menu_item: 'feature' },
                });
              }
              if (canRemovePost) {
                extraActions.push({
                  label: t('widgets.feed.remove'),
                  onClick: () => removeItem(item),
                  biData: { ...biData, menu_item: 'remove' },
                });
              }
            }
            return (
              <FeedThumb
                key={`${index}-${item.typeId}-${item.deviationId}-${item['isPinned']}`}
                withComments
                deviation={item}
                className={s['feed-item']}
                disablePinning={disablePinningAndFeaturing}
                withWatchOrSubscribeButton={withWatchOrSubscribeButtons}
                extraActions={extraActions}
              />
            );
          })}
        </MeasuredViewerContainerSizeContextProvider>
        <StreamPagination streamId={streamId} />
      </LoadableDuperbrowseContext>
    </ErrorBoundary>
  );
};

PostsFeed.displayName = 'PostsFeed';
export default PostsFeed;
