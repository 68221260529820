import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Url, CHECKOUT_IDS } from '@wix/da-url';
import { BiData, GiftSelectClickBiEvent } from '@wix/da-bi/pkg/events';

import { actions as modalActions } from '@wix/da-shared-react/pkg/Modals';
import { pushModal } from '@wix/da-shared-react/pkg/Modals/redux/basicActions';
import { showMobileSharePopup as showMobileSharePopupAction } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import { clickWatch } from '@wix/da-shared-react/pkg/WatchButton/redux/actionCreators';
import { getUserWatchState } from '@wix/da-shared-react/pkg/WatchButton/redux/selectors';
import { requestJoinGroup } from '@wix/da-shared-react/pkg/redux/groups/actions';
import { ModalType as SharedModalType } from '@wix/da-shared-react/pkg/Modals/redux/types';
import {
  inviteToGroup,
  requestCloseGroup,
  requestChangeFounder,
  requestFounderLeaveGroup,
} from '../../../../../actions/groups';

import {
  getProfileOwner,
  getProfileOwnerUser,
  getIsGroupProfile,
  isCurrentUserProfileOwner,
} from '../../../../../selectors/users';
import { getIsGroupMember } from '../../../../../selectors/groups';
import {
  getCanJoinGroup,
  getCanLeaveGroup,
  getCanInviteToGroup,
  getCanCloseGroup,
  getCanChangeFounder,
  getCanMessageAdmins,
  getCanRespondToNotifications,
} from '../../../../../selectors/privs';
import { SectionContext } from '../../../../Sections/SectionContext';
import { fetchProfileOwnerGiveStateSuccess } from '../../../../../actions/profileOwner';
import { pushPointsModal } from '../../../../../actions/gifts';

interface Item {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  biData?: any;
}

export default function useMoreActionItems() {
  const { t } = useTranslation();
  const section = useContext(SectionContext);
  const isBackroomSection = section === 'backroom';

  // Selectors
  const giveState = useSelector(getProfileOwner).giveState!;
  const isOwner = useSelector(isCurrentUserProfileOwner);
  const isGroup = useSelector(getIsGroupProfile);
  const profileOwnerUser = useSelector(getProfileOwnerUser);
  const isWatching = useSelector(
    state => getUserWatchState(state, profileOwnerUser).isWatching
  );

  // Privileges
  const isMember = useSelector(getIsGroupMember);
  const canJoinGroup = useSelector(getCanJoinGroup);
  const canLeaveGroup = useSelector(getCanLeaveGroup);
  const canInviteToGroup = useSelector(getCanInviteToGroup);
  const canCloseGroup = useSelector(getCanCloseGroup);
  const canChangeFounder = useSelector(getCanChangeFounder);
  const canMessageAdmins = useSelector(getCanMessageAdmins);
  const canRespondToNotifications = useSelector(getCanRespondToNotifications);
  // Leave group item has 1 label, and 3 different flows
  // 1. If canLeaveGroup, just show the leave group flow
  // 2. If !canLeaveGroup && canChangeFounder && !isBackroomSection - this
  //    means that the user is the founder of the group and cannot leave, they
  //    need to change the group founder, but cannot, since changing the founder
  //    can only be done in the backroom section, where the recruitment module
  //    lives, so we default to shared requestLeaveGroup action which will show
  //    the notification explaining this and redirect to group backroom
  // 3. If !canLeaveGroup && canChangeFounder && isBackroomSection - show the changeFounder flow
  // This covers 1. and 2.
  const showSharedLeaveGroup =
    canLeaveGroup || (canChangeFounder && !isBackroomSection);
  // And this covers 3.
  const showChangeFounder =
    !canLeaveGroup && canChangeFounder && isBackroomSection;

  // Actions
  const dispatch = useDispatch();
  const pushBadgeModal = (username: string, onBadgeSent?: () => void) =>
    dispatch(modalActions.pushBadgeModal(username, onBadgeSent));

  const onPushPointsModal = (username, title) =>
    dispatch(pushPointsModal(username, title));
  const onLlamaBadgeSent = () =>
    dispatch(fetchProfileOwnerGiveStateSuccess({ canBeGivenLlama: false }));
  const requestJoin = () => dispatch(requestJoinGroup(profileOwnerUser));
  const blockUser = () =>
    dispatch(
      pushModal(SharedModalType.USER_BLOCK, {
        params: {
          user: profileOwnerUser,
          redirectLocation: Url.browseHomeLink(),
        },
      })
    );
  const showMobileSharePopup = (url: string, title: string) =>
    dispatch(showMobileSharePopupAction(url, title));
  const changeFounder = () => dispatch(requestChangeFounder(profileOwnerUser));
  const leaveGroup = () => dispatch(requestFounderLeaveGroup(profileOwnerUser));
  const closeGroup = () => dispatch(requestCloseGroup(profileOwnerUser));
  const inviteUsers = () => dispatch(inviteToGroup(profileOwnerUser));
  const toggleWatch = () => dispatch(clickWatch(profileOwnerUser, isWatching));
  const openNotifications = () =>
    window.location.assign(Url.groupNotificationsLink(profileOwnerUser.userId));

  // Items
  let items: Item[] = [];
  if (isGroup) {
    return [
      isMember && {
        label: t(
          `navigation.subnavigation.${
            isWatching ? 'unwatchGroup' : 'watchGroup'
          }`
        ),
        onClick: toggleWatch,
      },
      showSharedLeaveGroup && {
        label: t('navigation.subnavigation.leaveGroup'),
        onClick: leaveGroup,
      },
      showChangeFounder && {
        label: t('navigation.subnavigation.leaveGroup'),
        onClick: changeFounder,
      },
      canRespondToNotifications && {
        label: t('navigation.subnavigation.groupNotifications'),
        onClick: openNotifications,
      },
      canInviteToGroup && {
        label: t('navigation.subnavigation.sendInvitation'),
        onClick: inviteUsers,
      },
      canCloseGroup && {
        label: t('navigation.subnavigation.closeGroup'),
        onClick: closeGroup,
      },
      canMessageAdmins && {
        label: t('navigation.subnavigation.messageAdmins'),
        onClick: () =>
          window.location.assign(Url.userNoteLink(profileOwnerUser.username)),
      },
      canJoinGroup && {
        label: t('navigation.subnavigation.requestJoin'),
        onClick: requestJoin,
      },
    ].filter(Boolean) as Item[];
  }
  if (!isOwner) {
    items = [
      {
        label: giveState.canBeGivenCore
          ? t!('navigation.subnavigation.giveCore')
          : t!('navigation.subnavigation.hasCore'),
        onClick: () =>
          window.location.assign(
            Url.giveCoreMembership(profileOwnerUser.username)
          ),
        disabled: !giveState.canBeGivenCore,
        biData: BiData<GiftSelectClickBiEvent>({
          evid: 275,
          menu_item_name: giveState.canBeGivenCore
            ? t!('navigation.subnavigation.giveCore')
            : t!('navigation.subnavigation.hasCore'),
        }),
      },
      {
        label: t!('navigation.subnavigation.givePoints'),
        onClick: () => {
          onPushPointsModal(
            profileOwnerUser.username,
            t!('navigation.subnavigation.givePoints')
          );
        },
      },
      {
        label: giveState.canBeGivenLlama
          ? t!('navigation.subnavigation.giveLlamaBadge')
          : t!('navigation.subnavigation.hasGotLlamaBadge'),
        onClick: () => {
          pushBadgeModal(profileOwnerUser.username, onLlamaBadgeSent);
        },
        disabled: !giveState.canBeGivenLlama,
      },
      {
        label: giveState.canBeGivenCake
          ? t!('navigation.subnavigation.giveCakeBadge')
          : t!('navigation.subnavigation.hasGotCakeBadge'),
        onClick: () => {
          window.location.assign(
            `${Url.checkoutLink(CHECKOUT_IDS.CAKE)}&foruserid=${
              profileOwnerUser.userId
            }`
          );
        },
        disabled: !giveState.canBeGivenCake,
      },
    ];
  }

  if (!isOwner) {
    items.push({
      label: t('navigation.subnavigation.blockUser'),
      onClick: blockUser,
    });
  }

  items.push({
    label: t('navigation.subnavigation.share'),
    onClick: () =>
      showMobileSharePopup(
        Url.userLink(profileOwnerUser.username),
        t('navigation.subnavigation.share.title', profileOwnerUser)
      ),
  });

  return items;
}
