import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import {
  getCurrentUser,
  getIsMobile,
} from '@wix/da-shared-react/pkg/publicSession/selectors';
import { showSubmitModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import { isGroup } from '@wix/da-shared-react/pkg/User/helpers';
import { AppState } from '../../../../../../types/store';
import { PagePriv } from '../../../../../../types/pagePrivileges';
import { getCanPerformPageAction } from '../../../../../selectors/privs';
import {
  isCurrentUserProfileOwner,
  getProfileOwnerUser,
} from '../../../../../selectors/users';
import { Props } from './Postbox';

export type StateProps = Pick<
  Props,
  | 'thisGroup'
  | 'currentUser'
  | 'canSubmitPoll'
  | 'canSubmitJournal'
  | 'canSubmitStatus'
>;
export type DispatchProps = Pick<Props, 'showSubmitModal'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  const profileOwnerUser = getProfileOwnerUser(state);
  const isOwner = isCurrentUserProfileOwner(state);
  const isMobile = getIsMobile(state);
  const isGroupPage = isGroup(profileOwnerUser);
  return {
    currentUser: getCurrentUser(state),
    thisGroup: isGroupPage ? profileOwnerUser : undefined,
    canSubmitJournal:
      !isMobile &&
      (isOwner ||
        (isGroupPage &&
          getCanPerformPageAction(state, PagePriv.CAN_SUBMIT_JOURNAL))),
    canSubmitPoll: isOwner,
    canSubmitStatus: isOwner,
  };
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  showSubmitModal,
};

export default connect(mapStateToProps, mapDispatchToProps);
