import React, { useRef, useState, useEffect } from 'react';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';
import { useController, useFormContext } from 'react-hook-form';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import { useEditor } from '@wix/da-shared-react/pkg/DaEditorWrapper/hooks';
import DaEditorWrapper from '@wix/da-shared-react/pkg/DaEditorWrapper/LoadableDaEditorWrapper';
import { EditorFlavourConfig } from '@wix/da-shared-react/pkg/DaEditorWrapper/types';
import { WORD_COUNT_FEATURE } from '@wix/da-shared-react/pkg/DaEditorWrapper/Features/wordCount';
import FormElement from './FormElement';

import s from './EditorField.scss';

interface Props {
  name: string;
  label: string;
  subLabel?: string;
  placeholder?: string;
  theme?: any;
  flavour: EditorFlavourConfig;
  maxLength?: number;
  withFooter?: boolean;
}

const EditorField: React.FC<Props> = ({
  name,
  label,
  subLabel,
  placeholder,
  theme,
  flavour,
  maxLength,
  withFooter,
}) => {
  const { current: id } = useRef(uniqueId('textarea'));
  const { control, register, errors } = useFormContext();
  const initialContent = control.defaultValuesRef.current[name];
  const error = get(errors, name);

  const {
    field: { onChange },
  } = useController({
    name,
    control,
  });

  // Editor is not a real form field, we need to register it
  useEffect(() => {
    register(name);
  }, [register, name]);

  const [charCount, setCharCount] = useState(0);
  const { editorToolbars, editorProps } = useEditor({
    onEditorChange: editorState => {
      const wordCountFeature = editorState.features.find(
        f => f.type === WORD_COUNT_FEATURE
      );
      const wordCount = wordCountFeature?.data?.chars;
      typeof wordCount === 'number' && setCharCount(wordCount);

      onChange && onChange(editorState);
    },
  });

  const FooterToolbar = editorToolbars?.INSERT_PLUGIN;

  return (
    <FormElement
      id={id}
      isLabelHidden={false}
      noBottomSpacing={false}
      label={label}
      subLabel={subLabel}
      validationResult={error && 'error'}
      details={error}
    >
      <DaEditorWrapper
        theme={theme}
        flavour={flavour}
        filterPaste={true}
        charLimit={maxLength}
        placeholder={placeholder}
        initialContent={initialContent}
        loadingComponent={LoadingIndicator}
        {...editorProps}
      />
      {withFooter && FooterToolbar && (
        <div className={theme['editor-footer']}>
          <div className={s['editor-footer-controls']}>
            <FooterToolbar />
          </div>
          {maxLength && (
            <div className={s['counter']}>
              ({charCount} / {maxLength})
            </div>
          )}
        </div>
      )}
    </FormElement>
  );
};
EditorField.displayName = 'EditorField';

export default EditorField;
