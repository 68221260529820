import React from 'react';
import { useTranslation } from 'react-i18next';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import SubscriptionTips from './SubscriptionTips';
import useTiersModuleCounts from './useTiersModuleCounts';
import s from './SubscriptionTipsBox.scss';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const SubscriptionTipsBox: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();
  const { draftCount, publishedCount, subscriberCount } =
    useTiersModuleCounts();

  return (
    <WidgetBox
      moduleId={moduleId}
      header={t('widgets.subscription_tips.header')}
    >
      <SubscriptionTips
        moduleId={moduleId}
        draftCount={draftCount}
        publishedCount={publishedCount}
        subscriberCount={subscriberCount}
        className={s['tips']}
      />
    </WidgetBox>
  );
};

SubscriptionTipsBox.displayName = 'SubscriptionTipsBox';
export default SubscriptionTipsBox;
