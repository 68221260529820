import React from 'react';
import { compose } from 'redux';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { ShopItemType } from '../../../../../types/shop';
import { Url } from '@wix/da-url';
import withSectionFetchSpinner from '../../../../decorators/withSectionFetchSpinner';
import ShopGrid from '../ShopGrid';
import PremiumContentEmptyState from '../_partials/PremiumContentEmptyState';
import { BiData, DefaultBiEvent } from '@wix/da-bi/pkg/events';
import UploadMore from '../_partials/UploadMore';
import { useSubmitUrl } from '@wix/da-shared-react/pkg/utils/hooks/useSubmitUrl';
import s from '../ShopSection.scss';

export interface Props {
  className?: string;
}

const enhance = compose(withSectionFetchSpinner);

export const ShopPremiumContent: React.FC<Props> = ({ className }) => {
  const SUBMIT_URL = useSubmitUrl(true);
  return (
    <BiLoggerContextProvider value={{ sectionname: 'Premium Downloads' }}>
      <div className={s['section-with-no-header']}>
        <ShopGrid
          className={className}
          itemType={ShopItemType.PREMIUM_CONTENT}
          emptyState={<PremiumContentEmptyState view={'large'} />}
          uploadMore={
            <UploadMore
              itemType={ShopItemType.PREMIUM_CONTENT}
              onCreate={() => {
                if (window) {
                  window.location.replace(Url.to(SUBMIT_URL));
                }
              }}
              biData={BiData<DefaultBiEvent>({
                evid: 2,
                click_info: 'create_premium_download',
                sectionname: 'premium_download',
              })}
            />
          }
        />
      </div>
    </BiLoggerContextProvider>
  );
};
ShopPremiumContent.displayName = 'ShopPremiumContent';

export default enhance(ShopPremiumContent) as React.ComponentType<Props>;
