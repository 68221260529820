import { ModuleType } from '../../types/modules';
import { AppState } from '../../types/store';
import { getModuleDataByModuleName } from './modules';
import {
  getDeviationById,
  getDeviationsByIds,
} from '@wix/da-shared-react/pkg/redux/deviations/selectors';
import { getModuleDataByModuleName as getModuleDataByModuleNameNewGrusers } from '@wix/da-gruser-shared/pkg/redux/selectors/modules';
import { getProfileGruser } from './users';

export const getSelectedTierContentType = (state: AppState) =>
  state.subscriptionsSection.tierContentType;

export const getSelectedTierEntityId = (state: AppState) =>
  state.subscriptionsSection.selectedTierEntityId;

export const getSelectedTier = (state: AppState) => {
  const selectedTierEntityId = getSelectedTierEntityId(state);
  if (!selectedTierEntityId) {
    return undefined;
  }
  return getDeviationById(state, selectedTierEntityId);
};

export const hasTiers = (state: AppState) => {
  const tiers = getModuleDataByModuleName(state, ModuleType.TIERS)?.tiers;
  return !!(tiers && tiers.length);
};

export const hasTiersNewGruser = state => {
  const tiers = getModuleDataByModuleNameNewGrusers(
    state,
    getProfileGruser(state),
    'tiers'
  )?.tiers;
  return !!(tiers && tiers.length);
};

export const hasPublishedTiers = (state: AppState) => {
  const tiers = getModuleDataByModuleName(state, ModuleType.TIERS)?.tiers;
  if (!tiers || !tiers?.length) {
    return false;
  }
  return getDeviationsByIds(state, tiers).some(tier => tier.isPublished);
};

export const hasPublishedTiersNewGrusers = state => {
  const tiers = getModuleDataByModuleNameNewGrusers(
    state,
    getProfileGruser(state),
    'tiers'
  )?.tiers;
  if (!tiers || !tiers?.length) {
    return false;
  }
  return tiers.some(tier => tier.isPublished);
};

export const getSelectedOrFirstTier = (state: AppState) => {
  let tierEntityId = getSelectedTierEntityId(state);
  if (!tierEntityId) {
    tierEntityId = getModuleDataByModuleName(state, ModuleType.TIERS)?.tiers[0];
  }
  return tierEntityId ? getDeviationById(state, tierEntityId) : undefined;
};

export const getSelectedOrFirstTierNewGrusers = state => {
  const tierEntityId = getSelectedTierEntityId(state);
  if (tierEntityId) {
    return getDeviationById(state, tierEntityId);
  }
  const firstTier = getModuleDataByModuleNameNewGrusers(
    state,
    getProfileGruser(state),
    'tiers'
  )?.tiers[0];

  return firstTier;
};

export const getSubscriptionsStats = (state: AppState) =>
  state.subscriptionsSection.stats;

export const getSubscriptionsChartData = (state: AppState) =>
  state.subscriptionsSection.chartData;
