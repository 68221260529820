import React from 'react';
import { useHistory } from 'react-router-dom';
import { yieldToMain } from '@wix/da-hooks/pkg/yieldToMain';

/**
 * this is a copy of what Link from react router was doing but with added
 * yieldToMain to fix INP on the subnav for mobile.
 *
 * Scrapped together from various react router docs
 */
const NavLink: React.FC<any> = React.forwardRef(
  ({ onClick, replace = false, state, target, to, location, ...rest }, ref) => {
    const history = useHistory();
    function handleClick() {
      history.push(to);
    }

    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        href={to}
        {...rest}
        onClick={event => {
          event.preventDefault();
          yieldToMain(() => {
            onClick?.(event);
            handleClick();
          });
        }}
        ref={ref}
        target={target}
      />
    );
  }
);

export default NavLink;
