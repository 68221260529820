import React from 'react';
import { useBiLogger } from '@wix/da-bi/pkg/hooks/useBiLogger';
import {
  BiData,
  BiContextProvidedValue,
  UserNameClickBiEvent,
} from '@wix/da-bi/pkg/events';
import { PapiGruserModuleWatching } from '@wix/da-papi-types';
import { ResourceType } from '@wix/da-shared-react/pkg/types/resource';
import UserGrid from '../../_partials/UserGrid/UserGrid';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';

interface Props {
  moduleId: number;
}

const Supporters: React.FC<Props> = ({ moduleId }) => {
  const logEvent = useBiLogger();

  const { moduleData } = useModuleData<PapiGruserModuleWatching>(moduleId);

  return (
    <UserGrid
      users={moduleData?.results || []}
      onUserClick={user => {
        logEvent(
          BiData<UserNameClickBiEvent>({
            evid: 101,
            sectionname: BiContextProvidedValue,
            widgetname: BiContextProvidedValue,
            typeid: ResourceType.USER,
            itemid: user.userId,
          })
        );
      }}
    />
  );
};
Supporters.displayName = 'Supporters';

export default Supporters;
