import { takeLatest, takeEvery, fork, all } from 'redux-saga/effects';
import { withOffset } from '@wix/da-shared-react/pkg/Stream';
import { sagas as sharedSagas } from '@wix/da-shared-react/pkg/redux/shared';
import { sisuFunnelSaga } from '@wix/da-shared-react/pkg/redux/sisuFunnel/saga';
import { PATH_CHANGE } from '../actions/app';
import fetchSectionData from './fetchSectionData';
import {
  callStreamSpecificSaga,
  callStreamSpecificSagaForPrevious,
} from './streams';
import commissionSagas from './commissions';
import pollStreamSagas from './polls';
import deviationSagas from './deviation';
import rootNewModulesSaga from './modules_new_grusers';
import rootAvatarSaga from './avatar';
import rootCoreBadgeSaga from './coreBadge';
import profileHeroEditorSaga from './profileHeroEditor';
import statsSection from './statsSection';
import biLogger from './biLogger';
import profileOwner from './profileOwner';
import gallectionSagas from './gallections';
import profileSkinsSaga from './profileSkins';
import customBoxTemplatesSaga from './customBoxTemplates';
import badgesSaga from './badges';
import groupsSaga from './groups';
import backroomSectionSaga from './backroomSection';
import sectionPrivateSaga from './sectionPrivate';
import rootQueryParamsSaga from './queryParams';

export default function* rootSaga(action) {
  yield all([
    takeLatest(PATH_CHANGE, fetchSectionData),
    takeEvery(
      withOffset.actions.STREAM__WITH_OFFSET__FETCH,
      callStreamSpecificSaga
    ),
    takeEvery(
      withOffset.actions.fetchPrevious,
      callStreamSpecificSagaForPrevious
    ),
    ...deviationSagas,
    ...commissionSagas,
    ...gallectionSagas,
    ...pollStreamSagas,
    fork(rootNewModulesSaga),
    fork(rootAvatarSaga),
    fork(rootCoreBadgeSaga),
    fork(profileHeroEditorSaga),
    fork(biLogger),
    fork(profileOwner),
    fork(statsSection),
    ...sharedSagas.map(fork),
    fork(sisuFunnelSaga),
    fork(profileSkinsSaga),
    fork(customBoxTemplatesSaga),
    fork(badgesSaga),
    fork(groupsSaga),
    fork(backroomSectionSaga),
    fork(sectionPrivateSaga),
    fork(rootQueryParamsSaga),
  ]);
}
