import React from 'react';
import classnames from 'classnames';
import { PapiUser, PapiModuleGroupAbout } from '@wix/da-papi-types';
import { useTranslation } from 'react-i18next';

import GhostButton from '@wix/da-ds/pkg/Buttons/GhostButton';
import Timestamp from '@wix/da-shared-react/pkg/Timestamp';
import TextRenderer from '@wix/da-shared-react/pkg/TextRenderer';
import GroupAboutViewer from '@wix/da-shared-react/pkg/DaEditorWrapper/Viewer/GroupAbout';
import AboutViewerTipTap from '@wix/da-editor-tiptap/pkg/components/Viewer/presets/UserAbout';

import { BulletItem } from '@wix/da-shared-react/pkg/Bullets';
import CalendarIcon from '@wix/da-ds/pkg/Icons/Calendar';
import DeviantsIcon from '@wix/da-ds/pkg/Icons/24x24/AllDeviants';
import Eye2Icon from '@wix/da-ds/pkg/Icons/Eye2';

import shortenNumber from '@wix/da-ds/pkg/utils/shortenNumber';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import { useModuleVersion } from '@wix/da-gruser-shared/pkg/hooks/useModule';

import s from './GroupAbout.scss';

export interface Props {
  className?: string;

  moduleId: number;

  // redux data
  profileOwnerUser: PapiUser;
  admins: number;
  pageviews: number;
  members: number;
  canJoin: boolean;
  canInvite: boolean;

  // redux actions
  messageAdmins: (group: PapiUser) => void;
  requestToJoin: (group: PapiUser) => void;
  inviteToGroup: (group: PapiUser) => void;
}

const GroupAbout: React.FC<Props> = ({
  className,

  moduleId,
  profileOwnerUser,
  admins,
  pageviews,
  members,
  canJoin,
  canInvite,

  messageAdmins,
  requestToJoin,
  inviteToGroup,
}) => {
  const { t } = useTranslation();
  const { moduleData } = useModuleData<PapiModuleGroupAbout>(moduleId);
  const moduleVersion = useModuleVersion(moduleId);

  const { description, foundationTs } = moduleData;

  return (
    <div className={classnames(s['root'], className)}>
      {description && (
        <div className={s['description']}>
          <TextRenderer
            textContent={description}
            reactViewer={GroupAboutViewer}
            tipTapViewer={AboutViewerTipTap}
            legacyTheme={'default'}
            viewerKey={`group-about-${moduleId}-${moduleVersion}`}
          />
        </div>
      )}
      {foundationTs && (
        <div>
          <BulletItem bullet={<CalendarIcon disableDefaultColors />}>
            {t('profileStats.founded', {
              postProcess: 'reactPostprocessor',
              formattedDate: (
                <Timestamp timestamp={new Date(foundationTs).toISOString()} />
              ),
            })}
          </BulletItem>
        </div>
      )}
      <div className={s['row']}>
        <BulletItem bullet={<DeviantsIcon disableDefaultColors />}>
          {t('common.stats.members', {
            formattedCount: shortenNumber(members),
            count: members,
          })}
        </BulletItem>
        <BulletItem bullet={<DeviantsIcon disableDefaultColors />}>
          {t('common.stats.admins', {
            formattedCount: shortenNumber(admins),
            count: admins,
          })}
        </BulletItem>
        <BulletItem bullet={<Eye2Icon disableDefaultColors />}>
          {t('common.stats.pageviews', {
            formattedCount: shortenNumber(pageviews),
            count: pageviews,
          })}
        </BulletItem>
      </div>
      <div className={s['row']}>
        {canInvite && (
          <GhostButton
            size="large"
            variant="main"
            onClick={() => inviteToGroup(profileOwnerUser)}
          >
            {t('widgets.group_about.view.button.invite')}
          </GhostButton>
        )}
        {canJoin && (
          <GhostButton
            size="large"
            variant="main"
            onClick={() => requestToJoin(profileOwnerUser)}
          >
            {t('widgets.group_about.view.button.join')}
          </GhostButton>
        )}
        <GhostButton
          size="large"
          variant="main"
          onClick={() => messageAdmins(profileOwnerUser)}
        >
          {t('widgets.group_about.view.button.messageAdmins')}
        </GhostButton>
      </div>
    </div>
  );
};

GroupAbout.displayName = 'GroupAbout';

export default GroupAbout;
