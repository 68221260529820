import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { updateModule } from '@wix/da-gruser-shared/pkg/redux/actions/modules';
import { deviationSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { PapiModuleTiers } from '@wix/da-papi-types';
import { withOffset } from '@wix/da-shared-react/pkg/Stream';
import {
  TIER_CONTENT_DEVIATIONS_PER_FETCH,
  TIER_CONTENT_DEVIATIONS_STREAM_GROUP_ID,
} from '../../../../../../streams/tierContent';
import { TIERS_STREAM } from '../../../../../../streams';

export default (
  gruser: Gruser,
  moduleId: number,
  moduleData: PapiModuleTiers
) => {
  const { tiers } = moduleData;
  const normalized = normalize(tiers, [deviationSchema]);

  return [
    withOffset.actions.initialize({
      streamId: TIERS_STREAM,
      groupId: TIER_CONTENT_DEVIATIONS_STREAM_GROUP_ID,
      itemsPerFetch: TIER_CONTENT_DEVIATIONS_PER_FETCH,
      entities: normalized.entities,
    }),
    updateModule(gruser, {
      id: moduleId,
      moduleMeta: {
        isInitialized: true,
      },
    }),
  ] as any;
};
