import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import {
  getDeviationType,
  isDeviationTextEditable,
  isDeviationAuthor,
} from '@wix/da-shared-react/pkg/DeviationViews/_helpers';
import { DeviationTypes } from '@wix/da-shared-react/pkg/types/deviation';
import {
  getCurrentUser,
  getIsMobile,
} from '@wix/da-shared-react/pkg/publicSession/selectors';
import { editDeviation } from '@wix/da-shared-react/pkg/redux/deviations/actionCreators';

import { AppState } from '../../../../../types/store';
import { pinPostOnFeed } from '../../../../actions/feed';
import {
  isCurrentUserProfileOwner,
  getIsCurrentGroupAdmin,
} from '../../../../selectors/users';
import { Props } from './FeedThumb';

type StateProps = Pick<Props, 'canPin' | 'canEdit'>;
type DispatchProps = Pick<Props, 'editItem' | 'pinItem' | 'unpinItem'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  ownProps
) => {
  const { deviation, disablePinning } = ownProps;
  const currentUser = getCurrentUser(state);
  const isMobile = getIsMobile(state);
  const isAuthor = isDeviationAuthor(deviation, currentUser);
  const isAdmin = getIsCurrentGroupAdmin(state);
  const isOwner = isCurrentUserProfileOwner(state);

  // TODO: for groups we will need a more elaborate check
  // for user profiles, all of these are basically = isOwner
  const canPin = !disablePinning && (isOwner || isAdmin);

  // Journals can't be edited on mobile
  const canEdit =
    isAuthor &&
    isDeviationTextEditable(deviation) &&
    (!isMobile || getDeviationType(deviation) !== DeviationTypes.JOURNAL);

  return {
    canPin,
    canEdit,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  ownProps
) => ({
  pinItem: () => dispatch(pinPostOnFeed({ item: ownProps.deviation })),
  unpinItem: () => dispatch(pinPostOnFeed({ item: undefined })),
  editItem: () =>
    dispatch(
      editDeviation({
        deviation: ownProps.deviation,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps);
