import { put, call, all, select } from 'redux-saga/effects';
import { matchRoutes } from 'react-router-config';
import { ALL_FOLDER_NAME, SCRAPS_FOLDER_NAME } from '@wix/da-url';
import {
  getPageSize,
  getPagingMode,
} from '@wix/da-shared-react/pkg/publicSession/selectors';
import { init as gruserInit } from '@wix/da-gruser-shared/pkg/redux/actions/';
import { purgeAllModules } from '../actions/modules';
import {
  fetchSection,
  fetchSectionSuccess,
  fetchSectionPrivateSuccess,
} from '../actions/sections';
import { routes, getSectionKeys } from '../routes';
import { DEVIATIONS_PER_FETCH_COUNT } from '../streams/folderDeviations';
import { PapiRequestDaUserProfileInitBackroom } from '@wix/da-papi-types';
import { PapiInitGallectionRequestParams } from '../../types/papi';
import { PostsType } from '../../types/posts';
import { FULLTEXT_JOURNALS_PER_FETCH, POSTS_PER_FETCH } from '../streams/posts';
import { DEFAULT_BACKROOM_SECTION } from '../../constants';
import { GroupNotesFolderType } from '../../types/backroomSection';
import { getCustomInitActionsForWidgets } from '../components/UserWidgets/redux/initActionsForWidgets';

import { isApiError, requestPuppy } from '@wix/da-http-client';

function* fetch(section, params) {
  return yield call(
    requestPuppy,
    {
      method: 'get',
      url: `init/${section}`,
      params: {
        ...params,
      },
    },
    undefined,
    'dauserprofile'
  );
}

export default function* fetchSectionData(action) {
  const { location } = action;

  const routeData = matchRoutes(routes, location.pathname).pop();
  const sectionKey = routeData.route.key;
  const sectionKeys = getSectionKeys();

  if (!sectionKeys.includes(sectionKey)) {
    return;
  }

  yield put(fetchSection(sectionKey));

  const routeParams = routeData.match.params;

  let requestParams;

  switch (sectionKey) {
    case 'gallery':
    case 'favourites':
    case 'private_collections':
      const pageSize = yield select(getPageSize);
      const pagingMode = yield select(getPagingMode);
      const isPaginated = pagingMode === 'page';
      const gallectionRequestParams: PapiInitGallectionRequestParams = {
        username: routeParams.username,
        deviations_limit: isPaginated ? pageSize : DEVIATIONS_PER_FETCH_COUNT,
        with_subfolders: true,
      };

      if (routeParams.folderId !== undefined) {
        gallectionRequestParams.folder_id = routeParams.folderId;
      } else {
        switch (routeParams.folderName) {
          case SCRAPS_FOLDER_NAME:
            gallectionRequestParams.scraps_folder = true;
            break;
          case ALL_FOLDER_NAME:
            gallectionRequestParams.all_folder = true;
            break;
          default:
        }
      }

      requestParams = gallectionRequestParams;
      break;
    case 'shop':
      requestParams = {
        username: routeParams.username,
        shop_section: routeParams.shopSection,
      };
      break;
    case 'posts':
      requestParams = {
        username: routeParams.username,
        posts_section: routeParams.postsSection,
        limit: FULLTEXT_JOURNALS_PER_FETCH,
        order: routeParams.sortOrder,
      };
      if (routeParams.postsSection === PostsType.POLLS) {
        requestParams.limit = POSTS_PER_FETCH;
      } else {
        requestParams.expand = 'deviation.fulltext';
      }
      break;
    case 'subscriptions':
      requestParams = {
        username: routeParams.username,
        section: routeParams.tierContentType,
        tier_deviationid: routeParams.tierDeviationid,
      };
      break;
    case 'backroom':
      requestParams = {
        username: routeParams.username,
        backroom_section:
          routeParams.backroomSection || DEFAULT_BACKROOM_SECTION,
        folder: GroupNotesFolderType.INBOX,
        offset: 0,
      } as PapiRequestDaUserProfileInitBackroom;
      break;
    case 'adoptabobbles':
      requestParams = {
        username: routeParams.username,
      };
      break;
    default:
      requestParams = {
        username: routeParams.username,
      };
  }

  const result = yield call(fetch, sectionKey, requestParams);

  if (result && isApiError(result)) {
    if (result.errorCode === 302 && result.errorDetails?.redirectUrl) {
      window.location.replace(result.errorDetails.redirectUrl);
    }
  }

  if (result && !isApiError(result)) {
    const customInitActionsForWidgets = getCustomInitActionsForWidgets({
      requestParams,
      initData: result,
      section: sectionKey,
    });

    const { page: gruserPage, ...pureGruser } = result.gruser;
    const gruserInitAction = gruserInit({ gruser: pureGruser, gruserPage });
    const sectionInitActions = customInitActionsForWidgets;
    sectionInitActions.push(gruserInitAction);
    yield put(purgeAllModules());
    yield all(sectionInitActions.map(initAction => put(initAction)));
    yield put(fetchSectionSuccess(sectionKey));
    yield put(
      fetchSectionPrivateSuccess(result.gruser?.page?.isPrivate || false)
    );
  }
}
