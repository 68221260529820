/** The contents of a user's Bio (bio text and images and interests)
 *  in an optionally collapsible disclosure */
import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiEditorText } from '@wix/da-papi-types';
import TextRenderer from '@wix/da-shared-react/pkg/TextRenderer';
import DeviantIdViewer from '@wix/da-shared-react/pkg/DaEditorWrapper/Viewer/DeviantId';
import Separator from '@wix/da-shared-react/pkg/Separator';
import CollapsibleBlock from '@wix/da-shared-react/pkg/CollapsibleBlock';
import Button from '@wix/da-shared-react/pkg/Button';
import { isEditorTextEmpty } from '@wix/da-shared-react/pkg/DaEditorWrapper/utils';
import { AboutMeMode } from '../types';
import Interests from '../Interests';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import AboutViewer from '@wix/da-editor-tiptap/pkg/components/Viewer/presets/UserAbout';

import s from './CollapsibleBio.scss';

/** this is the fixed width right side of the UP home tab */
const COLLAPSE_WHEN_THIS_TALL = 360;

interface Props {
  mode: AboutMeMode;
  /** user widget system module and version */
  moduleId: number;
  moduleVersion: number;
  /** formatted text and images for user's biography */
  bio?: PapiEditorText;
  /** list of user's interests, Q&A style */
  interests?: {
    id: number;
    value: string;
  }[];
}

/** Renders a collapsible bio if mode === AboutMeMode.CARD,
 *  a plainly wrapped one otherwise */
export const Bio: React.FC<Props> = props => {
  const { mode, bio, interests, moduleId, moduleVersion } = props;
  const { t } = useTranslation();

  const contents = (
    <>
      {bio && !isEditorTextEmpty(bio) && (
        <div className={s['bio-layout']}>
          <TextRenderer
            textContent={bio}
            legacyTheme="default"
            reactViewer={DeviantIdViewer}
            viewerKey={`bio-${moduleId}-${moduleVersion}`}
            cookieType={MeasuredCookieType.USER_ABOUT}
            tipTapViewer={AboutViewer}
            defaultSize={{
              desktop: { width: 534, height: 400 },
              mobile: { width: 380, height: 400 },
            }}
          />
        </div>
      )}

      {interests && interests.length > 0 && (
        <>
          <Separator className={s['interests-separator']} />
          <Interests interests={interests} />
        </>
      )}
    </>
  );

  return mode === AboutMeMode.CARD ? (
    <CollapsibleBlock
      className={s['bio']}
      maxHeight={COLLAPSE_WHEN_THIS_TALL}
      showFade
      fadeClassName={s['collapsible-fade']}
      renderToggleButton={({ collapsed, onClick }) => (
        <Button
          view="b3"
          className={classnames(
            s['collapsible-button'],
            collapsed && s['collapsed']
          )}
          onClick={onClick as any}
        >
          {t(collapsed ? 'common.showMore' : 'common.showLess')}
        </Button>
      )}
    >
      {contents}
    </CollapsibleBlock>
  ) : (
    <div className={s['bio']}>{contents}</div>
  );
};
Bio.displayName = 'Bio';
export default Bio;
