import React, { lazy, Suspense } from 'react';
import classnames from 'classnames';
import { renderRoutes } from 'react-router-config';
import { RouteComponentProps } from 'react-router';
import { PapiUser } from '@wix/da-papi-types';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import DaApp from '@wix/da-shared-react/pkg/DaApp';
import { RuntimeEnvironment } from '@wix/da-shared-react/pkg/types';
import { SectionProvider } from '../Sections/SectionContext';
import {
  ThemeOptions,
  ThemeOverride,
  ThemeSkin,
} from '@wix/da-react-context/pkg/ThemeContext';
import { Promo } from '@wix/da-react-context/pkg/publicSession';
import GruserContext from '@wix/da-gruser-shared/pkg/context/GruserContext';
import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi/';
import SiteHeader from '@wix/da-shared-react/pkg/SiteHeader';
import SiteFooter from '@wix/da-shared-react/pkg/SiteFooter';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import DrawerLoadable from '@wix/da-feedback-shared/pkg/Drawer/DrawerLoadable';
import FeedbackPageLoadable from '@wix/da-feedback-shared/pkg/FeedbackPage/FeedbackPageLoadable';
import SaleBanner from '@wix/da-shared-react/pkg/banners/SaleBanner';
import ProfileIdentity from '../UserWidgets/ProfileIdentity';
import SubNavBar from '../SubNavBar';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { ResourceType } from '@wix/da-shared-react/pkg/types/resource';
import ProfileHeroV2 from '../UserWidgets/ProfileHeroV2';
import ProfileHeroMobile from '../UserWidgets/ProfileHeroMobile';
import MonetizationPromoBanner from '../UserWidgets/MonetizationPromoBanner';
import DraggableProfileHero from '../UserWidgets/ProfileHeroV2/DraggableProfileHero';
import ProfileHeroEditControls from '../UserWidgets/ProfileHeroV2/ProfileHeroEditControls';
import { routes } from '../../routes';
import { ProfileSkin } from '../../../types/profileSkins';
import { PageType } from '../../../types/pageType';
import BackgroundContainer from './BackgroundContainer';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import ConditionalWrapper from '@wix/da-ds/pkg/ConditionalWrapper';
import modalRenderer from '../Modals';
import { composeModalRenderers } from '@wix/da-shared-react/pkg/Modals/helpers';
import { adoptsModalRenderer } from '@wix/da-adopts-shared/pkg/project-tools';
import { submitModalRenderer } from '@wix/da-submit-shared/pkg/project-tools';
import { feedbackModalRenderer } from '@wix/da-feedback-shared/pkg/project-tools';
import GroupCreation from '../Pages/GroupCreation';

import s from './App.scss';

const ProfileSkinSelector = lazy(
  () => import(/* webpackChunkName: "owner" */ '../ProfileSkinSelector')
);

export const CONTENT_CONTAINER_ID = 'content-container';

export interface Props {
  activeSection: string;
  language: string;
  isMobile: boolean;
  isOwner?: boolean;
  isAuthorizedToCustomize?: boolean;
  profileOwner: PapiUser;
  isSectionLoading?: boolean;
  theme?: ThemeOptions;
  themeSkin?: ThemeSkin;
  userAgent?: string;
  environment?: RuntimeEnvironment;
  showProfileHeroAid?: boolean;
  isHeroEditingActive?: boolean;
  isFeedbackPageOpen?: boolean;
  appUrl: string;
  pageType?: PageType;
  promos?: Promo[];
  onAppRendered(): void;
  onPathChange(location: object): void;
  onInitFallbackBadgeCheck(): void;

  gruser: Gruser;

  /**
   * We set up some CSS classes, disable some ThemeOverrides, and more if the profile owner has a profile skin active.
   */
  profileSkin?: ProfileSkin;
  isSkinSelectorShown?: boolean;
  isDuperbrowseActive?: boolean;
  hasBadgesInstalled: boolean;
  flags: string[];
}

export interface State {
  reducedMotion?: boolean;
}

class App extends React.Component<Props & RouteComponentProps<any>, State> {
  static defaultProps = {
    language: 'en',
  };

  state = {
    reducedMotion: false,
  };

  pageContentContainer: React.RefObject<any> = React.createRef();
  sectionContentContainer: React.RefObject<any> = React.createRef();

  private doneShowingEditControls = false;

  componentDidMount() {
    this.props.onAppRendered();
    if (this.props.pageType !== 'profile') {
      return;
    }

    const pageContainerClassList = this.pageContentContainer.current!.classList;
    pageContainerClassList.add(s['show-edit-controls']);
    // on SSR rendering we show edit controls
    // and hide them after 2 seconds here using direct node manipulation
    // to be make it more performant
    // we also need to set this.doneShowingEditControls so that
    // a subsequent re-render doesn't make them re-appear
    setTimeout(() => {
      pageContainerClassList.remove(s['show-edit-controls']);
      this.doneShowingEditControls = true;
      // this is not done via state to not re-render App.tsx in a bit after page load
      // while the user is likely to be scrolling.
      // We track isFeedbackPageOpen flag so that the App is re-rendered when we
      // toggle the feedback page, otherwise edit controls are never disabled.
    }, 2000);

    if (window && window.matchMedia) {
      const mql = window.matchMedia('(prefers-reduced-motion: reduce)');
      if (mql.matches) {
        this.setState({ reducedMotion: true });
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.pageType !== 'profile') {
      return true;
    }

    if (
      nextProps.theme !== this.props.theme ||
      nextProps.themeSkin !== this.props.themeSkin ||
      nextProps.profileSkin !== this.props.profileSkin ||
      nextProps.showProfileHeroAid !== this.props.showProfileHeroAid ||
      nextProps.isHeroEditingActive !== this.props.isHeroEditingActive ||
      nextProps.isFeedbackPageOpen !== this.props.isFeedbackPageOpen ||
      nextProps.isSkinSelectorShown !== this.props.isSkinSelectorShown ||
      nextProps.isDuperbrowseActive !== this.props.isDuperbrowseActive ||
      nextProps.activeSection !== this.props.activeSection
    ) {
      return true;
    }

    if (nextState.reducedMotion !== this.state.reducedMotion) {
      return true;
    }

    const section = this.props.location.pathname.split('/')[2];
    if (
      [
        'gallery',
        'favourites',
        'private-collections',
        'subscriptions',
      ].includes(section)
    ) {
      const nextSection = nextProps.location.pathname.split('/')[2];
      if (section !== nextSection) {
        this.props.onPathChange(nextProps.location);
      } else if (this.props.location.search && !nextProps.location.search) {
        // Treat returning to section homepage as a section change
        this.props.onPathChange(nextProps.location);
      }
    } else {
      // Using not full url to make navigation to <a name /> by hash works
      // or passing params to section data loader
      if (nextProps.location.pathname !== this.props.location.pathname) {
        this.props.onPathChange(nextProps.location);
      }
    }

    return this.props.isSectionLoading !== nextProps.isSectionLoading;
  }

  render() {
    const {
      language,
      environment,
      appUrl,
      isHeroEditingActive,
      pageType,
      gruser,
      profileOwner,
      profileSkin,
      themeSkin,
    } = this.props;

    return (
      <GruserContext.Provider value={{ gruser, gruserOwner: profileOwner }}>
        <DaApp
          theme={
            this.isProfileSkinActive()
              ? profileSkin?.skin?.baseTheme
              : undefined
          }
          /* We force the themeSkin off when the skin is active, if they add green based skins pass the skin here instead of NONE */
          themeSkin={this.isProfileSkinActive() ? ThemeSkin.NONE : themeSkin}
          disableHeadTags
          language={language}
          environment={environment}
          bodyClassName={classnames(isHeroEditingActive && s['disable-scroll'])}
          appUrl={appUrl}
          ncContextProps={{
            PageComponent: FeedbackPageLoadable,
            DrawerComponent: DrawerLoadable,
          }}
          modalsRenderFunc={composeModalRenderers(
            modalRenderer,
            adoptsModalRenderer,
            submitModalRenderer,
            feedbackModalRenderer
          )}
        >
          {pageType === 'profile'
            ? this.renderProfilePage()
            : this.renderNonProfilePage()}
        </DaApp>
      </GruserContext.Provider>
    );
  }

  renderProfilePage() {
    const {
      isMobile,
      activeSection,
      profileOwner,
      showProfileHeroAid,
      isHeroEditingActive,
      theme,
      themeSkin,
    } = this.props;
    const profileOwnerId = profileOwner.userId;

    return (
      <SectionProvider value={activeSection}>
        <BiLoggerContextProvider
          value={{
            typeid: ResourceType.USER,
            sectionname: 'profile',
            section_typeid: ResourceType.USER,
            itemid: profileOwnerId,
          }}
        >
          <ConditionalWrapper
            condition={this.isProfileSkinActive()}
            wrap={child => {
              return (
                <ThemeOverride theme={theme!} className={themeSkin}>
                  {child}
                </ThemeOverride>
              );
            }}
          >
            <SiteHeader />
          </ConditionalWrapper>

          {isHeroEditingActive ? (
            <DraggableProfileHero className={s['profile-hero']} />
          ) : (
            !isMobile && (
              <ProfileHeroV2
                showProfileHeroAid={showProfileHeroAid}
                className={s['profile-hero']}
              />
            )
          )}
          <div
            className={classnames(
              s['above-hero-content'],
              isHeroEditingActive && s['editing-hero']
            )}
          >
            {this.renderContent()}
            {!isHeroEditingActive && !isMobile && <MonetizationPromoBanner />}
            <SiteFooter className={s['special-profile-footer']} />
          </div>
        </BiLoggerContextProvider>
      </SectionProvider>
    );
  }

  renderNonProfilePage() {
    const { pageType } = this.props;
    return (
      <ErrorBoundary debugComponent="NonProfilePage">
        <SiteHeader />
        {pageType === 'groupCreation' && <GroupCreation />}

        <SiteFooter className={s['special-profile-footer']} />
      </ErrorBoundary>
    );
  }

  renderContent() {
    const {
      isMobile,
      isAuthorizedToCustomize,
      activeSection,
      profileSkin,
      showProfileHeroAid,
    } = this.props;
    const showEditControls =
      !this.doneShowingEditControls && isAuthorizedToCustomize;
    const dontUseOverflowAndStickyTogether =
      isMobile && ['posts', 'home'].includes(activeSection);

    return (
      <div
        id={CONTENT_CONTAINER_ID}
        ref={this.pageContentContainer}
        className={classnames(
          s['content-container'],
          showEditControls && s['show-edit-controls'],
          showProfileHeroAid && s['show-ftue-controls'],
          isMobile && s['new-profile-hero']
        )}
      >
        <BackgroundContainer
          id="background-container"
          className={s['profile-hero-overlay']}
          profileSkin={profileSkin}
          activeSection={activeSection}
        />
        <div className={s['content-container-with-paddings']}>
          <ErrorBoundary debugComponent="ProfileIndentity">
            <div className={s['profile-identity-container']}>
              {isMobile ? (
                <ProfileHeroMobile />
              ) : (
                <ProfileIdentity className={s['profile-identity']} />
              )}
            </div>
          </ErrorBoundary>
          <SubNavBar />
          <div
            ref={this.sectionContentContainer}
            className={classnames(
              s['section-container'],
              // removes the overflow: hidden from this element so that the sticky
              // positioned 'next post' button will properly follow the post as you scroll
              dontUseOverflowAndStickyTogether && s['no-overflow-prevention']
            )}
          >
            {this.renderSaleBanner()}
            {renderRoutes(routes)}
          </div>
        </div>
        {this.renderProfileHeroEdit()}
        {this.renderSkinSelector()}
      </div>
    );
  }

  renderProfileHeroEdit = () => {
    const { isAuthorizedToCustomize, isMobile, showProfileHeroAid } =
      this.props;

    if (!isAuthorizedToCustomize || isMobile) {
      return null;
    }
    return (
      <ProfileHeroEditControls
        className={classnames(
          s['profile-hero-edit'],
          showProfileHeroAid && s['show-ftue-controls']
        )}
        showProfileHeroAid={showProfileHeroAid}
      />
    );
  };

  renderSkinSelector = () => {
    const { isSkinSelectorShown } = this.props;

    if (!isSkinSelectorShown) {
      return null;
    }

    return (
      <Suspense
        fallback={
          <LoadingIndicator
            coverMode
            view="llama"
            usePortal
            className={s['skin-selector-loading']}
          />
        }
      >
        <ProfileSkinSelector />
      </Suspense>
    );
  };

  renderSaleBanner = () => {
    const { promos, isOwner, isMobile, profileOwner } = this.props;
    const currentPromo = promos?.[0];

    if (!currentPromo) {
      return;
    }

    const promoteBuyingCore =
      !profileOwner.isGroup &&
      // only show a self sale on the user's own page
      ((currentPromo.type === 'self' && isOwner) ||
        // only show a gift sale on other user's page
        (currentPromo.type === 'gift' &&
          !isOwner &&
          profileOwner.type === 'regular'));
    return (
      promoteBuyingCore && (
        <SaleBanner
          isNarrow={isMobile}
          className={s['sale-banner']}
          giftRecipient={
            currentPromo.type === 'gift' ? profileOwner.username : undefined
          }
          discountValuePercent={currentPromo.discountValuePercent}
          endDate={currentPromo.endDate}
          referralType={ReferralType.PROMOTION_BANNER_PROFILE}
        />
      )
    );
  };

  isProfileSkinActive() {
    const { profileSkin, isDuperbrowseActive } = this.props;
    return (
      profileSkin && profileSkin.skinId !== 'official' && !isDuperbrowseActive
    );
  }
}

export default App;
