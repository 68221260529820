import {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useTransition,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { usePreviousValue } from '@wix/da-hooks/pkg/usePreviousValue';
import useModuleEndpoint from '@wix/da-gruser-shared/pkg/hooks/useModuleEndpoint';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import {
  getNotesOffset,
  getSelectedFolder,
  getNotesSearch,
  getSelectedNoteId,
} from '../../../selectors/backroomSection';

import { setSelectedFolder } from '../../../actions/backroomSection';

import { GroupNotesFolderType } from '../../../../types/backroomSection';

const GROUP_NOTES_PER_FETCH = 10;

export function useGroupNotes(moduleId: number) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [, startTransition] = useTransition();
  const limit = GROUP_NOTES_PER_FETCH;
  const offset = useSelector(getNotesOffset) || 0;
  const moduleDataSearch = useSelector(getNotesSearch) || '';
  const folder = useSelector(getSelectedFolder) || GroupNotesFolderType.INBOX;
  const setFolder = useCallback(
    (newFolder: GroupNotesFolderType) => dispatch(setSelectedFolder(newFolder)),
    [dispatch]
  );
  const selectedNoteId = useSelector(getSelectedNoteId);
  const {
    moduleData: { hasMore, nextOffset },
  } = useModuleData<{ hasMore: boolean; nextOffset?: number }>(moduleId);
  const hasLess = offset > 0;

  const { getEndpoint, isFetching, hasJustFinishedFetching, error } =
    useModuleEndpoint(moduleId, 'group_notes/');

  const [currentNoteId, setCurrentNoteId] = useState(selectedNoteId);
  const [search, setSearch] = useState(moduleDataSearch);

  const [selectedNoteIds, setSelectedNoteIds] = useState<number[]>([]);
  const selectNoteId = useCallback(
    (noteId, isChecked) => {
      if (isChecked) {
        // check
        setSelectedNoteIds([...selectedNoteIds, noteId]);
      } else {
        // uncheck
        setSelectedNoteIds(selectedNoteIds.filter(id => id !== noteId));
      }
    },
    [setSelectedNoteIds, selectedNoteIds]
  );

  const reset = useCallback(() => {
    setSearch('');
    setSelectedNoteIds([]);
    setIsLoading(false);
  }, []);

  const changeFolder = useCallback(
    (newFolder: GroupNotesFolderType) => {
      if (newFolder !== folder) {
        startTransition(() => {
          reset();
          setIsLoading(true);
          setFolder(newFolder);
        });
      }
    },
    [folder, reset, setFolder]
  );

  const previousSearch = usePreviousValue(search, '');
  const searchChanged = previousSearch !== search;
  const previousFolder = usePreviousValue(folder, GroupNotesFolderType.INBOX);
  const folderChanged = previousFolder !== folder;
  const shouldRefetch = searchChanged || folderChanged;

  const fetchNotes = useCallback(
    newOffset => {
      if (isFetching) {
        return;
      }
      setIsLoading(true);
      getEndpoint({
        offset: newOffset,
        folder,
        search,
        limit,
      });
    },
    [folder, search, limit, getEndpoint, isFetching]
  );

  const fetchPrevious = useCallback(() => {
    const newOffset = Math.max(0, offset - GROUP_NOTES_PER_FETCH);
    fetchNotes(newOffset);
  }, [offset, fetchNotes]);

  const fetchNext = useCallback(() => {
    fetchNotes(nextOffset);
  }, [nextOffset, fetchNotes]);

  const debouncedRefetch = useMemo(
    () => debounce(fetchNotes, 1000),
    [fetchNotes]
  );

  useEffect(() => {
    if (!isFetching && shouldRefetch) {
      debouncedRefetch({ folder, search });
    }
  }, [debouncedRefetch, isFetching, shouldRefetch, folder, search]);

  useEffect(() => {
    if (hasJustFinishedFetching || error) {
      setIsLoading(false);
    }
  }, [hasJustFinishedFetching, error]);

  return {
    isFetching,
    isLoading,

    hasMore,
    hasLess,
    fetchNext,
    fetchNotes,
    fetchPrevious,

    search,
    setSearch,

    reset,
    selectNoteId,

    currentNoteId,
    setCurrentNoteId,

    selectedNoteIds,
    setSelectedNoteIds,

    folder,
    setFolder,
    changeFolder,
  };
}

export default useGroupNotes;
