import { useSelector } from 'react-redux';
import { GruserReduxSlice } from '@wix/da-gruser-shared/pkg/types/store';
import { ModuleName } from '@wix/da-gruser-shared/pkg/types/modules';
import useGruser from '@wix/da-gruser-shared/pkg/hooks/useGruser';
import { getModuleDataByModuleName } from '@wix/da-gruser-shared/pkg/redux/selectors/modules';

/** Returns the first Gruser module found with the given module name */
export default function useModuleDataByName(moduleName: ModuleName) {
  const { gruser } = useGruser();
  return useSelector(state =>
    getModuleDataByModuleName(state as GruserReduxSlice, gruser, moduleName)
  );
}
