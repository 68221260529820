import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import defaultRemoveConfirmation from '@wix/da-shared-react/pkg/widgets/defaultRemoveConfirmation';
import TiersPromoBox from './TiersPromoBox';

export const getTiersPromoWidgetConfig = ({ t, dispatch }): WidgetConfig => ({
  viewComponent: TiersPromoBox,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-18-tier.svg',
    title: t('widgets.tiers.addButtonHeader'),
    description: t('widgets.tiers.description'),
  },
});
