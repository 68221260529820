import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';

import ddt from '@wix/da-ddt';
import { ModuleName } from '@wix/da-gruser-shared/pkg/types/modules';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import Flex from '@wix/da-ds/pkg/Flex';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import MeasuredGrid, { GridElement } from '@wix/da-shared-react/pkg/Grid';

import { ShopItemType } from '../../../../../../types/shop';
import withSectionFetchSpinner from '../../../../../decorators/withSectionFetchSpinner';
import LoadableDuperbrowseContext from '../../../../../contexts/LoadableDuperbrowseContext';
import StreamPagination from '../../../_partials/StreamPagination';
import { DEFAULT_GRID_ELEMENT_HEIGHT } from './constants';
import { RenderCardProps, ShopCardSubject } from './types';
import useShopGridStream from './useShopGridStream';
import UploadMore from './UploadMoreButton';
import ShopFilters from './ShopFilters';
import { getBreakpoints } from './breakpoints';

// TODO: remove with profile_shop_pagination_2
const logger = ddt.createLogger('shopgrid');

export interface Props {
  itemType: ShopItemType;
  moduleName: ModuleName;
  uploadMoreBiClickInfo: string;
  invitationToUpload: React.ComponentType;
  className?: string;
  gridElementHeight?: number;
  renderCard: (props: RenderCardProps) => React.ReactNode;
}

export const ShopGridWithFilters: React.FC<Props> = ({
  itemType,
  moduleName,
  uploadMoreBiClickInfo,
  invitationToUpload: InvitationToUpload,
  gridElementHeight = DEFAULT_GRID_ELEMENT_HEIGHT,
  className,
  renderCard,
}) => {
  const { t } = useTranslation();
  const isDesktop = !useContext(MobileContext);
  const [areFiltersExpanded, setFiltersExpanded] = useState(isDesktop);

  const {
    streamId,
    items = [],
    itemsPerFetch,
    total = 0,
    totalPages,
    hasMore,
  } = useShopGridStream<ShopCardSubject>(itemType);
  logger.log(JSON.stringify({ items: items?.length, hasMore }));
  logger.log(JSON.stringify({ total, itemsPerFetch, totalPages }));
  const hasProductsForSale = total >= 1;

  const renderElement = useCallback(
    (element: GridElement) => {
      return renderCard({
        subject: items[element.index] as ShopCardSubject,
        ...element,
      });
    },
    [items, renderCard]
  );

  return (
    <Flex className={className} direction="column" gap={24}>
      {hasProductsForSale && (
        <ErrorBoundary debugComponent="ShopGridWithFilters">
          <UploadMore itemType={itemType} biClickInfo={uploadMoreBiClickInfo} />

          <ShopFilters
            moduleName={moduleName}
            itemCount={items.length}
            // these are here and not just inside ShopFilters because we
            // often need to know the expanded state to re-calc breakpoints
            // TODO: maybe design will be ok with the same elementcount
            // regardless of expanded or not
            areFiltersExpanded={areFiltersExpanded}
            setFiltersExpanded={setFiltersExpanded}
          >
            <LoadableDuperbrowseContext
              streamId={streamId}
              parent={{ title: t('duperbrowse.backButton.profile.shop') }}
            >
              <MeasuredGrid
                cookieType={MeasuredCookieType.USER_PROFILE_SHOP}
                breakpoints={getBreakpoints(gridElementHeight)}
                preserveAspectRatio={false}
                enableScrollOptim
                elementCount={items.length}
                renderElement={renderElement}
              />
            </LoadableDuperbrowseContext>

            <StreamPagination streamId={streamId} totalPages={totalPages} />
          </ShopFilters>
        </ErrorBoundary>
      )}

      {!hasProductsForSale && <InvitationToUpload />}
    </Flex>
  );
};

ShopGridWithFilters.displayName = 'ShopGridWithFilters';

const enhance = compose(withSectionFetchSpinner);
export default enhance(ShopGridWithFilters) as React.ComponentType<Props>;
