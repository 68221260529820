import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BiData, GivePointsClickBiEvent } from '@wix/da-bi/pkg/events';
import ModalFormDialog, {
  ModalFormDialogProps,
} from '@wix/da-shared-react/pkg/Popup/ModalFormDialog';
import { POINTS_MAX_GIVE_TO_OTHER_DEVIANT_VALUE } from '@wix/da-shared-react/pkg/utils/points';
import PointsInput from '@wix/da-shared-react/pkg/PointsInput';
import Textarea from '@wix/da-shared-react/pkg/Textarea';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import FooterExtras from './FooterExtras';
import useModuleEndpoint from '@wix/da-gruser-shared/pkg/hooks/useModuleEndpoint';
import s from './DonatePointsPopup.scss';

const INIT_POINTS_VALUE = 80;

export type Props = {
  username: string;
  moduleId: number;
  close?: () => void;
} & Omit<ModalFormDialogProps, 'children'>;

export const DonatePointsPopup: React.FC<Props> = ({
  username,
  moduleId,
  close,
  ...formPopupProps
}) => {
  const { t } = useTranslation();
  const {
    postEndpoint: donate,
    isFetching: isDonating,
    error,
  } = useModuleEndpoint(moduleId, 'donations/give');
  const isMobile = useContext(MobileContext);

  const [pointsAmount, setPointsAmount] = useState(INIT_POINTS_VALUE);
  const [pointsInputError, setPointsInputError] = useState<string>();
  const [textareaValue, setTextareaValue] = useState<string>();

  const onSubmit = useCallback(() => {
    donate({
      amount: pointsAmount,
      message: textareaValue,
    });
  }, [donate, pointsAmount, textareaValue]);

  const onClose = useCallback(() => close && close(), [close]);

  useEffect(() => {
    if (isDonating && !error) {
      onClose();
    }
  }, [isDonating, error, onClose]);

  const maxValue = POINTS_MAX_GIVE_TO_OTHER_DEVIANT_VALUE;

  return (
    <ModalFormDialog
      isOpen={true}
      title={
        isMobile
          ? t('givePointsPopup.title.mobile')
          : t('givePointsPopup.title', { username: username })
      }
      subtitle={isMobile ? undefined : t('givePointsPopup.subtitle')}
      submitBtnLabel={t('givePointsPopup.button.primary')}
      disabledSubmit={!!pointsInputError || isDonating}
      onClose={onClose}
      onCancel={onClose}
      footerExtras={<FooterExtras />}
      onSubmit={onSubmit}
      submitBiData={BiData<GivePointsClickBiEvent>({
        evid: 272,
        points_total: pointsAmount,
        sent_personal_message:
          textareaValue && textareaValue.length > 0 ? 1 : 0,
      } as any)}
      {...formPopupProps}
    >
      <div className={s['content-wrapper']}>
        <div className={s['give-points-to-user']}>
          Donate Points to <strong>{username}</strong>
        </div>
        <div className={s['give-points-motivation']}>
          {t('givePointsPopup.givePointsMotivation')}
        </div>
        <PointsInput
          className={s['points-input']}
          label={t('givePointsPopup.pointsInputLabel')}
          value={pointsAmount}
          maxValue={maxValue}
          errorMessage={pointsInputError}
          onChange={(value, inputError) => {
            setPointsAmount(value);
            if (error) {
              setPointsInputError(inputError);
            } else {
              setPointsInputError(undefined);
            }
            if (value > maxValue) {
              // custom max value error
              setPointsInputError(
                t('givePointsPopup.error.biggerThanMaxValue', { maxValue })
              );
            }
          }}
        />
        <Textarea
          value={textareaValue}
          onChange={value => setTextareaValue(value)}
          wrapperClassName={s['textarea-wrapper']}
          placeholder={t('givePointsPopup.textarea.placeholder')}
          maxLength={250}
        />
        {error && (
          <div className={s['error-message']}>
            {t(
              error === 'unknown'
                ? 'widgets.donations.error.unexpectedErrorOccurred'
                : error
            )}
          </div>
        )}
      </div>
    </ModalFormDialog>
  );
};
DonatePointsPopup.displayName = 'DonatePointsPopup';

export default DonatePointsPopup;
